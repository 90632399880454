import {
  BorderBox,
  Flex,
  StyledOcticon,
  Link,
  themeGet,
  Text,
} from '@primer/components'
import {LinkExternalIcon} from '@primer/octicons-react'
import {Link as GatsbyLink} from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const NavLink = styled(Link)`
  &.active {
    font-weight: ${themeGet('fontWeights.bold')};
    color: ${themeGet('colors.gray.8')};
  }
`

function NavItems({items}) {
  return (
    <>
      {items.map((item) => (
        <BorderBox
          key={item.title}
          borderWidth={0}
          borderRadius={0}
          borderTopWidth={1}
          p={4}
        >
          <Flex flexDirection="column">
            {item.children ? (
              <Flex flexDirection="column" mt={2}>
                <Text>{item.title}</Text>
                {item.children.map((child) => (
                  <NavLink
                    key={child.title}
                    as={GatsbyLink}
                    to={child.url}
                    activeClassName="active"
                    display="block"
                    py={1}
                    mt={2}
                    paddingLeft={4}
                    fontSize={1}
                  >
                    {child.title}
                  </NavLink>
                ))}
              </Flex>
            ) : (
              <NavLink
                as={GatsbyLink}
                to={item.url}
                activeClassName="active"
                partiallyActive={true}
                color="inherit"
              >
                {item.title}
              </NavLink>
            )}
          </Flex>
        </BorderBox>
      ))}
      <BorderBox borderWidth={0} borderTopWidth={1} borderRadius={0} p={4}>
        <Link href="https://github.com/ludeeus" color="inherit" target="_blank">
          <Flex justifyContent="space-between" alignItems="center">
            GitHub
            <StyledOcticon icon={LinkExternalIcon} color="gray.7" />
          </Flex>
        </Link>

        <Link
          href="https://twitter.com/ludeeus"
          color="inherit"
          target="_blank"
        >
          <Flex justifyContent="space-between" alignItems="center">
            Twitter
            <StyledOcticon icon={LinkExternalIcon} color="gray.7" />
          </Flex>
        </Link>
      </BorderBox>
    </>
  )
}

export default NavItems
