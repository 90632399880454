import {Box, Flex, Link, Sticky, Text} from '@primer/components'
import {SearchIcon, ThreeBarsIcon} from '@primer/octicons-react'
import {Link as GatsbyLink} from 'gatsby'
import React from 'react'
import {ThemeContext} from 'styled-components'
import useSiteMetadata from '../use-site-metadata'
import DarkButton from './dark-button'
import MobileSearch from './mobile-search'
import NavDrawer, {useNavDrawerState} from './nav-drawer'
import Search from './search'

export const HEADER_HEIGHT = 66

function Header({isSearchEnabled}) {
  const theme = React.useContext(ThemeContext)
  const [isNavDrawerOpen, setIsNavDrawerOpen] = useNavDrawerState(
    theme.breakpoints[2],
  )
  const [isMobileSearchOpen, setIsMobileSearchOpen] = React.useState(false)
  const siteMetadata = useSiteMetadata()
  return (
    <Sticky>
      <Flex
        height={HEADER_HEIGHT}
        px={[3, null, null, 4]}
        alignItems="center"
        justifyContent="space-between"
        bg="black"
      >
        <Flex alignItems="center">
          <>
            <Text
              display={['none', null, null, 'inline-block']}
              color="blue.4"
              fontFamily="mono"
              mx={2}
            >
              //
            </Text>
            <Link as={GatsbyLink} to="/" color="blue.4" fontFamily="mono">
              {siteMetadata.shortName}
            </Link>
          </>
        </Flex>

        <Flex>
          <Box display={['none', null, null, 'block']} ml={4}>
            <Search />
          </Box>
          <Flex display={['flex', null, null, 'none']}>
            <>
              <DarkButton
                aria-label="Search"
                aria-expanded={isMobileSearchOpen}
                onClick={() => setIsMobileSearchOpen(true)}
              >
                <SearchIcon />
              </DarkButton>
              <MobileSearch
                isOpen={isMobileSearchOpen}
                onDismiss={() => setIsMobileSearchOpen(false)}
              />
            </>

            <DarkButton
              aria-label="Menu"
              aria-expanded={isNavDrawerOpen}
              onClick={() => setIsNavDrawerOpen(true)}
              ml={3}
            >
              <ThreeBarsIcon />
            </DarkButton>
            <NavDrawer
              isOpen={isNavDrawerOpen}
              onDismiss={() => setIsNavDrawerOpen(false)}
            />
          </Flex>
        </Flex>
      </Flex>
    </Sticky>
  )
}

Header.defaultProps = {
  isSearchEnabled: true,
}

export default Header
