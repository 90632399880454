import {BorderBox, Flex, Link, Text} from '@primer/components'
import {XIcon} from '@primer/octicons-react'
import {Link as GatsbyLink} from 'gatsby'
import debounce from 'lodash.debounce'
import React from 'react'
import navItems from '../nav.yml'
import useSiteMetadata from '../use-site-metadata'
import DarkButton from './dark-button'
import Drawer from './drawer'
import NavItems from './nav-items'

export function useNavDrawerState(breakpoint) {
  // Handle string values from themes with units at the end
  if (typeof breakpoint === 'string') {
    breakpoint = parseInt(breakpoint, 10)
  }
  const [isOpen, setOpen] = React.useState(false)

  const onResize = React.useCallback(() => {
    if (window.innerWidth >= breakpoint) {
      setOpen(false)
    }
  }, [setOpen])

  const debouncedOnResize = React.useCallback(debounce(onResize, 250), [
    onResize,
  ])

  React.useEffect(() => {
    if (isOpen) {
      window.addEventListener('resize', debouncedOnResize)
      return () => {
        // cancel any debounced invocation of the resize handler
        debouncedOnResize.cancel()
        window.removeEventListener('resize', debouncedOnResize)
      }
    }
  }, [isOpen, debouncedOnResize])

  return [isOpen, setOpen]
}

function NavDrawer({isOpen, onDismiss}) {
  const siteMetadata = useSiteMetadata()
  return (
    <Drawer isOpen={isOpen} onDismiss={onDismiss}>
      <Flex
        flexDirection="column"
        height="100%"
        bg="gray.9"
        style={{overflow: 'auto', WebkitOverflowScrolling: 'touch'}}
      >
        <Flex flexDirection="column" flex="0 0 auto" color="blue.2" bg="gray.9">
          <BorderBox
            borderWidth={0}
            borderRadius={0}
            borderBottomWidth={1}
            borderColor="gray.7"
          >
            <Flex
              py={3}
              pl={4}
              pr={3}
              alignItems="center"
              justifyContent="space-between"
            >
              <DarkButton aria-label="Close" onClick={onDismiss}>
                <XIcon />
              </DarkButton>
            </Flex>
          </BorderBox>
        </Flex>
        {navItems.length > 0 ? (
          <Flex
            flexDirection="column"
            flex="1 0 auto"
            color="gray.7"
            bg="gray.0"
          >
            <Link
              as={GatsbyLink}
              to="/"
              display="inline-block"
              color="inherit"
              fontFamily="mono"
              mx={4}
              my={4}
            >
              {siteMetadata.shortName}
            </Link>
            <NavItems items={navItems} />
          </Flex>
        ) : null}
      </Flex>
    </Drawer>
  )
}

export default NavDrawer
